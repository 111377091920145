import { showMessage } from '@/components/Message/message'
import { app } from '@/main'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import md5 from 'js-md5'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css' // 引入进度条样式
import apis from './apis'

/*
isShowMessage: 是否显示消息提示框
*/

function logOut() {
  router.push({ name: 'loginIndex' })
}

const axiosInstance = axios.create({
  headers: {
    Authorization: 'Basic c3dvcmQ6c3dvcmRfc2VjcmV0',
    common: {
      'Content-Type': 'application/json'
    }
  },
  isCode: undefined // 指定的code码不显示提示弹窗
})

axiosInstance.interceptors.response.use(
  (response) => {
    if (typeof response.data === 'object') {
      // 文件流不作弹窗提示
      if (response.data instanceof Blob) {
        NProgress.done()
        return response
      } else {
        response.data.data = JSON.parse(decrypt(response.data.data));
      }
      let { data, config } = response
      // 登陆的接口不返回code === 0, 用登陆成功后返回的token来判断接口是否成功
      data.success = data.code === 200 || (data.access_token && data.refresh_token)
      !data.success && !(data.code == config.isCode) && showMessage({ msg: response.data.msg, icon: 'failed' })
    }
    NProgress.done()
    return response.data
  },
  (error) => {
    if (error.response.status === 401) {
      showMessage({ icon: 'failed', msg: app.$t('登录信息已过期，请重新登录') })
      logOut()
    } else {
      error.response.data.data = JSON.parse(decrypt(error.response.data.data))
      showMessage({ msg: error.response.data.msg, icon: 'failed' })
    }
    NProgress.done()
  }
)

const PASSWORDURL = [apis.addUserInfo, apis.login, apis.modiyPassWord]
const PASSWORDNAME = ['password', 'rePassword']
axiosInstance.interceptors.request.use(
  (config) => {
    let formatLocale = store.getters['language/formatLocale']
    if (formatLocale) config.headers['Language'] = formatLocale
    !config.noNProgress && NProgress.start()
    if (PASSWORDURL.includes(config.url)) {
      // 密码加密
      try {
        if (config.data && Object.keys(config.data).length) {
          Object.keys(config.data).forEach((item) => {
            if (PASSWORDNAME.includes(item)) {
              let MD5PASSWORD = md5(config.data[item])
              config.data[item] = MD5PASSWORD
            }
          })
        }
      } catch (err) {
        console.log(err)
      }
    }
    const access_token = localStorage.getItem('_access_token') || false
    if (access_token) {
      config.headers['blade-auth'] = 'bearer ' + access_token
    }
    if(config?.CustomContentType) { // 自定义ContentType
      config.headers['Content-Type'] = config.CustomContentType
    }
    if (config.data && !(config.data instanceof FormData)) {
      // post 参数加密
      let data = encrypt(JSON.stringify(config.data)) // 对数据进行加密处理

      config.data = { param: data } // 将字节数组转换为 URL 安全的 Base64 编码
    }
    if (config.params) {
      // get参数加密
      let data = encrypt(JSON.stringify(config.params))
      config.params = { param: data }
    }
    return config
  },
  (error) => Promise.reject(error.response)
)
window.axios = axiosInstance
export default axiosInstance

// 后端提供的 AES 密钥和初始化向量
const AES_KEY = 'Zgzx7ey9LPfPev6XuD2lENSbszww0K4E'
const AES_IV = 'abHTef0198HGr89J'

function encrypt(plaintext) {
  const key = CryptoJS.enc.Utf8.parse(AES_KEY)
  const iv = CryptoJS.enc.Utf8.parse(AES_IV)
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plaintext), key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encodeToUrlSafeString(encrypted.ciphertext) // 调用自定义的 base64 编码函数
}

function encodeToUrlSafeString(encryptedBytes) {
  let base64String = CryptoJS.enc.Base64.stringify(encryptedBytes)
  let urlSafeBase64 = base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
  return urlSafeBase64
}
/**
 * @description: 出参解密
 * @param {*} ciphertext
 * @return {*}
 */
function decrypt(ciphertext) {
  const key = CryptoJS.enc.Utf8.parse(AES_KEY)
  const iv = CryptoJS.enc.Utf8.parse(AES_IV)

  // 首先进行自定义的 Base64 解码
  let base64String = ciphertext
    .replace(/-/g, '+')
    .replace(/_/g, '/')
    .replace(/[^A-Za-z0-9+/]/g, '')
  let ciphertextBytes = CryptoJS.enc.Base64.parse(base64String)

  const decrypted = CryptoJS.AES.decrypt(
    {
      ciphertext: ciphertextBytes
    },
    key,
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  )

  return decrypted.toString(CryptoJS.enc.Utf8)
}