import Ueditor from './ueditor/index.js';
import Vue from 'vue';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
const components = [
  Ueditor
];

function install (Vue, opt = {}) {
  Vue.prototype.$axios = opt.axios || window.axios;
  Vue.use(ElementUI)
  components.map(component => {
    Vue.component(component.name, component);
  });
}

if (window.Vue) {
  install(window.Vue);
} else {
  install(Vue);
}

export default install;