/*
 * @Author: --🌞
 * @Date: 2021-08-18 14:10:27
 * @LastEditors: luis
 * @LastEditTime: 2024-03-06
 * @Description:
 * @FilePath: /merchantsys-vue/src/utils/InputFilter.js
 */

import stringUtils from './stringUtils'

const addListener = function (el, type, fn) {
  el.addEventListener(type, fn, false)
}

// 去掉空格
const spaceFilter = function (el) {
  addListener(el, 'input', () => {
    el.value = el.value.replace(/\s+/, '')
  })
}

// 限制只能输入整数和小数（适用于价格类、最多两位小数）
const priceFilter = function (el, binding, vnode) {
  addListener(el, 'input', () => {
    let value = stringUtils.clearNoNum(el.value)
    const directive = vnode.data.directives[0] || {}
    if (directive.name == 'model') {
      //更新v-model绑定数据
      vnode.context[directive.expression] = value
    } else {
      el.value = value
    }
  })
}

const priceFormatFilter = function (el) {
  addListener(el, 'input', () => {
    let value = stringUtils.clearNoNum(el.value) + ''
    value = comdify(value)
    el.value = value
  })
}

function comdify(n) {
  var re = /\d{1,3}(?=(\d{3})+$)/g
  var n1 = n.replace(/^(\d+)((\.\d+)?)$/, function (s, s1, s2) {
    return s1.replace(re, '$&,') + s2
  })
  return n1
}

const cardFormatFilter = function (el) {
  addListener(el, 'input', () => {
    let value = stringUtils.cardFormat(el.value) + ''
    el.value = value
  })
}

export default {
  bind(el, binding, vnode) {
    if (el.tagName.toLowerCase() !== 'input') {
      el = el.getElementsByTagName('input')[0]
    }
    spaceFilter(el)
    switch (binding.arg) {
      case 'price':
        priceFilter(el, binding, vnode)
        break
      case 'priceFormat':
        priceFormatFilter(el)
        break
      case 'card':
        cardFormatFilter(el)
        break
      default:
        console.warn('未知指令类型', binding.arg)
        break
    }
  }
}
