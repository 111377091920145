/*
 * @Description:
 * @Author: luis
 * @Date: 2023-11-18
 * @LastEditors: luis
 * @FilePath: \merchantsys-vue\src\service\request.js
 */
import apis from './apis'
import axiosInstance from './axios'


/**
 * @description: 商品分组树
 * @return {*}
 */
export async function getGroupTree() {
    try {
      const data = await axiosInstance.get(apis.getGroupTree)
      data.data = removeEmptyListNode(data.data)
      return data
    } catch (e) {
      console.log(e)
    }
  }

/**
 * @description: 订单管理列表
 * @return {*}
 */
export async function getOrderManagement(payload) {
  try {
    const data = await axiosInstance.post(apis.orderManagement, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 订单详情
 * @return {*}
 */
export async function getOrderDetails(orderNo) {
    try {
      const data = await axiosInstance.get(apis.orderDetails, { params: { orderNo } })
      return data
    } catch (e) {
      console.log(e)
    }
  }


/**
 * @description: 物流详情
 * @return {*}
 */
export async function logisticsDetailById(logisId) {
  try {
    const data = await axiosInstance.get(apis.logisticsDetailById, { params: { logisId } })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 修改备注
 * @return {*}
 */
export async function updateRemarks(payload) {
    try {
      const data = await axiosInstance.post(apis.updateRemarks, payload)
      return data
    } catch (e) {
      console.log(e)
    }
}


/**
 * @description: 修改实付金额
 * @return {*}
 */
export async function changePrice(payload) {
    try {
      const data = await axiosInstance.post(apis.changePrice, payload)
      return data
    } catch (e) {
      console.log(e)
    }
}

/**
 * @description: 在线下单支持物流公司列表
 * @return {*}
 */
export async function getCompanies() {
  try {
    const data = await axiosInstance.get(apis.companies)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 发货
 * @return {*}
 */
export async function Ship(payload) {
  try {
    const data = await axiosInstance.post(apis.Ship, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 修改物流订单
 * @return {*}
 */
export async function modify(payload) {
  try {
    const data = await axiosInstance.post(apis.modify, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 根据提货码获取订单列表
 * @return {*}
 */
export async function orderQueryByPickCode(params) {
  try {
    const data = await axiosInstance.get(apis.orderQueryByPickCode, {params})
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: B端商家自提单已发货
 * @return {*}
 */
export async function orderClickSend(params) {
  try {
    const data = await axiosInstance.get(apis.orderClickSend, {params})
    return data
  } catch (e) {
    console.log(e)
  }
}

function removeEmptyListNode(data) {
    // 清理空节点
    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].listNode && data[i].listNode.length === 0) {
          delete data[i].listNode
        } else {
          removeEmptyListNode(data[i].listNode)
        }
      }
    }
    return data
}


// 订单发货页面
/**
 * @description: 列表接口
 * @return {*}
 */
export async function queryOrderShipment(payload) {
  try {
    const data = await axiosInstance.post(apis.queryOrderShipment, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 打印拣货单
 * @return {*}
 */
export async function PrintPickingList(payload) {
  try {
    const data = await axiosInstance.post(apis.PrintPickingList, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}


/**
 * @description: 打印面单和运单
 * @return {*}
 */
export async function PrintSLWB(payload) {
  try {
    const data = await axiosInstance.post(apis.PrintSLWB, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}


/**
 * @description: 申请运单
 * @return {*}
 */
export async function ApplyForWaybill(payload) {
  try {
    const data = await axiosInstance.post(apis.ApplyForWaybill, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 合并发货
 * @return {*}
 */
export async function mergeShipments(payload) {
  try {
    const data = await axiosInstance.post(apis.mergeShipments, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}


/**
 * @description: 取消发货
 * @return {*}
 */
export async function CancelShipment(payload) {
  try {
    const data = await axiosInstance.post(apis.CancelShipment, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}


/**
 * @description: 标记
 * @return {*}
 */
export async function remarkPickList(payload) {
  try {
    const data = await axiosInstance.post(apis.remarkPickList, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 备货完成
 * @return {*}
 */
export async function complete(payload) {
  try {
    const data = await axiosInstance.get(apis.complete, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}


/**
 * @description: //获取运单运费和查询物流公司名称
 * @return {*}
 */
export async function getFreightPre(payload) {
  try {
    const data = await axiosInstance.post(apis.getFreightPre, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 订单导入
 * @return {*}
 */
export async function orderUpload(payload) {
  try {
    const data = await axiosInstance.post(apis.orderUpload, payload, {  CustomContentType: 'multipart/form-data' })
    return data
  } catch (e) {
    console.log(e)
  }
}