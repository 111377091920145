import Vue from 'vue';
import Message from '@/components/Message';
var Showing = false

function showMessage (options) {
    if(Showing) return
    let duration = options?.duration || 3000
    const MessageConstructor = Vue.extend(Message);
    const instance = new MessageConstructor({
        propsData: options
    }).$mount();
    document.body.appendChild(instance.$el);
    Showing = true
    // 两秒后隐藏组件
    setTimeout(() => {
        instance.$destroy(); // 销毁实例
        instance.$el.parentNode.removeChild(instance.$el); // 从 DOM 中移除
        Showing = false
    }, duration + 200);
}

Vue.prototype.$showMessage = showMessage
export { showMessage }
// message 目前仅支持 成功-succeed，失败--failed，警告--warn
// $showMessage({
//     icon: 'none', // 成功-succeed，失败--failed，警告--warn   默认：warn
//     msg: '', // 提示语  默认：'未知错误'
//     duration: '' // 设置自动消失时间，默认两秒后消失
// })
// 已限制已经显示提示语时，不展示其他提示语，后期调整