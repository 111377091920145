/*
 * @Description:
 * @Author: yanxing
 * @Date: 2023-11-18
 * @LastEditors: yanxing
 * @FilePath: /merchantsys-vue/src/service/request.js
 */
import apis from './apis'
import axiosInstance from './axios'

/**
 * @description: 获取工作台数据
 * @return {*}
 */
export async function getStagingData(payload) {
    // { params: payload }
    try {
      const data = await axiosInstance.get(apis.getStagingData)
      return data
    } catch (e) {
      console.log(e)
    }
  }