/*
 * @Description:
 * @Author: luis
 * @Date: 2023-11-18
 * @LastEditors: luis
 * @FilePath: \merchantsys-vue\src\store\modules\user.js
 */
const Languages = require("@/utils/Languages.js")
 let systemLanguage = Languages.SystemDefaultLanguage()



  const state = {
    Locale: systemLanguage
  }
  
  const getters = {
    // 格式化语言
    formatLocale: (state) => {
      let newLocale = ''
      let UpperCaseLocale =  state.Locale.toUpperCase()
      switch (UpperCaseLocale) {
        case 'ZHTW':
          newLocale = 'ZH-HK'
          break;
        case 'ZHCN':
          newLocale = 'ZH-CN'
          break;
        case 'EN':
          newLocale = 'EN'
          break;
        default:
          break;
      }
      return newLocale;
    }
  }
  
  const mutations = {
    MT_LOCALE: (state, payload) => {
      state.Locale = payload
    },
   
  }
  
  const actions = {
    AT_LOCALE({ commit }, payload) {
      commit('MT_LOCALE', payload)
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
  