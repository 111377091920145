/*
 * @Description:
 * @Author: luis
 * @Date: 2023-11-18
 * @LastEditors: luis
 * @FilePath: /merchantsys-vue/src/service/request.js
 */
import apis from './apis'
import axiosInstance from './axios'
/**
 * @description: 获取平台入驻协议
 * @return {*}
 */
export async function getSettlementAgreement(payload) {
  try {
    const data = await axiosInstance.get(`https://show.richandyoung.cn/thmall/resource/authAgreement.js`, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}
// 登陆
export async function login(payload) {
  try {
    const data = await axiosInstance.post(apis.login, { ...payload, userType: 0 })
    return data
  } catch (e) {
    console.log(e)
  }
}
// 登陆
export async function getOrder() {
  try {
    const data = await axiosInstance.get(apis.getOrder)
    return data
  } catch (e) {
    console.log(e)
  }
}
/**
 * @description: 店铺认证发送邮箱验证码
 * @return {*}
 */
export async function sendEmailCode(payload) {
  try {
    const data = await axiosInstance.get(apis.sendEmailCode, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 店铺注册发送邮箱验证码
 * @return {*}
 */
export async function sendStoreRegisterEmailCode(payload) {
  try {
    const data = await axiosInstance.get(apis.sendStoreRegisterEmailCode, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 发送手机验证码
 * @return {*}
 */
export async function sendSms(payload) {
  try {
    const data = await axiosInstance.post(apis.sendSms, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 验证码验证
 * @return {*}
 */
export async function verifyCaptcha(payload) {
  try {
    const data = await axiosInstance.post(apis.verifyCaptcha, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 新增店铺认证
 * @return {*}
 */
export async function submit(payload) {
  try {
    const data = await axiosInstance.post(apis.submit, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 保存店铺注册信息
 * @return {*}
 */
export async function saveRegisterStoreInformation(payload) {
  try {
    const data = await axiosInstance.post(apis.saveRegisterStoreInformation, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 邮箱验证码校验
 * @return {*}
 * String emailCode (邮箱验证码)
 * String emailName( 邮箱信息地址)
 * Integer type( 1管理员信息/2店铺信息)
 */
export async function validateEmailCode(payload) {
  try {
    const data = await axiosInstance.get(apis.validateEmailCode, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 公司信息(校验商业登记证以及社会统一信用代码去重)
 * @return {*}
 * type 地区区分 1澳门 2大陆 3香港
 * businessRegistrationNum 商业登记证编号
 * unifiedCreditCode 统一社会信用代码
 */
export async function validateDeduplication(payload) {
  try {
    const data = await axiosInstance.post(apis.validateDeduplication, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 公司信息(校验商业登记证以及社会统一信用代码去重)
 * @return {*}
 * type 地区区分 1澳门 2大陆 3香港
 * businessRegistrationNum 商业登记证编号
 * unifiedCreditCode 统一社会信用代码
 */
export async function queryList(payload) {
  try {
    const data = await axiosInstance.get(apis.queryList, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 公司信息(校验商业登记证以及社会统一信用代码去重)
 * @return {*}
 * type 地区区分 1澳门 2大陆 3香港
 * businessRegistrationNum 商业登记证编号
 * unifiedCreditCode 统一社会信用代码
 */
export async function queryCompanyStoreAuthInfo(payload) {
  try {
    const data = await axiosInstance.get(apis.queryCompanyStoreAuthInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询已认证店铺信息
 * @return {*}
 * account 用户账号  17362460646
 */
export async function selectByAdminInfo() {
  try {
    const data = await axiosInstance.get(apis.selectByAdminInfo)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 修改已认证店铺信息
 * @return {*}
 */
export async function saveAndUpdateStoreInfo(payload) {
  try {
    const data = await axiosInstance.post(apis.saveAndUpdateStoreInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 商家修改店铺信息提交
 * @return {*}
 */
export async function submitStoreRecordInfo(payload) {
  try {
    const data = await axiosInstance.post(apis.submitStoreRecordInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 新增已认证店铺打烊信息
 * @return {*}
 * reasonForClose 打烊原因 { 1: 个人原因暂时离开, 2: 节假日休息, 3: 不可控力因素（政策/灾难等） }
 * sampleStartTime 开始时间
 * sampleEndTime 结束时间
 * closeDescribe 打烊说明
 * sampleType 打烊类型 1
 * 店铺Id id
 */
export async function saveStoreSampleInfo(payload) {
  try {
    const data = await axiosInstance.post(apis.saveStoreSampleInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 取消打烊
 * @return {*}
 * id 店铺id
 * sampleType 打烊类型 0
 */
export async function cancelClose(payload) {
  try {
    const data = await axiosInstance.get(apis.cancelClose, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 关店
 * @return {*}
 * 店铺id id
 */
export async function closeStore(payload) {
  try {
    const data = await axiosInstance.get(apis.closeStore, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

// ============================== W W S == 商品模块 ======================

/**
 * @description: 商品列表
 * @return {*}
 */
export async function getProductList(payload) {
  try {
    const data = await axiosInstance.post(apis.getProductList, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 新增参数/规格 名称
 * @return {*}
 */
export async function addParamLabel(payload) {
  try {
    const data = await axiosInstance.post(apis.addLabel, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 新增参数/规格 值
 * @return {*}
 */
export async function addParamValue(payload) {
  try {
    const data = await axiosInstance.post(apis.addvalue, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 新增/编辑 商品
 * @return {*}
 */
export async function ProductAddEdit(payload) {
  try {
    const data = await axiosInstance.post(apis.addEditProduct, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 商品类目树
 * @return {*}
 */
export async function getCategoryTree() {
  try {
    const data = await axiosInstance.get(apis.getCategoryTree)
    data.data = removeEmptyListNode(data.data)
    return data
  } catch (e) {
    console.log(e)
  }
}
function removeEmptyListNode(data) {
  // 清理空节点
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].listNode && data[i].listNode.length === 0) {
        delete data[i].listNode
      } else {
        removeEmptyListNode(data[i].listNode)
      }
    }
  }
  return data
}
/**
 * @description: 商品分组树
 * @return {*}
 */
export async function getGroupTree() {
  try {
    const data = await axiosInstance.get(apis.getGroupTree)
    data.data = removeEmptyListNode(data.data)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 当前店铺一级分组
 * @return {*}
 */
export async function getStoreFirstGroup() {
  try {
    const data = await axiosInstance.get(apis.getStoreFirstGroup)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 当前店铺二级&三级分组
 * @return {*}
 */
export async function getStoreFirstChildGroup(payload) {
  try {
    const data = await axiosInstance.get(apis.getStoreFirstChildGroup, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 删除一级分组
 * @return {*}
 */
export async function delStoreGroup(payload) {
  try {
    const data = await axiosInstance.get(apis.delStoreGroup, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}
/**
 * @description: 获取分组下的商品列表
 * @return {*}
 */
export async function getGroupPro(payload) {
  try {
    const data = await axiosInstance.get(apis.getGroupPro, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 新增编辑 一级店铺分类
 * @return {*}
 */
export async function addEditFirstGroup(payload) {
  try {
    const data = await axiosInstance.post(apis.addEditFirstGroup, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 保存店铺子集分类，带一级分组Id storeBalance
 * @return {*}
 */
export async function saveChildGruop(payload) {
  try {
    const data = await axiosInstance.post(apis.saveChildGruop, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 店铺余额
 * @return {*}
 */
export async function storeBalance(payload) {
  try {
    const data = await axiosInstance.get(apis.storeBalance, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 提现记录
 * @return {*}
 */
export async function storeRecord(payload) {
  try {
    const data = await axiosInstance.get(apis.storeRecord, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 申请提现
 * @return {*}
 */
export async function depositApply(payload) {
  try {
    const data = await axiosInstance.get(apis.depositApply, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 获取店铺银行账户信息
 * @return {*}
 */
export async function storeBankAccount(payload) {
  try {
    const data = await axiosInstance.get(apis.storeBankAccount, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 保存店铺银行账户信息
 * @return {*}
 */
export async function saveBankAccount(payload) {
  try {
    const data = await axiosInstance.post(apis.saveBankAccount, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 商品品牌
 * @return {*}
 */
export async function getProductBrands() {
  try {
    const data = await axiosInstance.get(apis.getProductBrands)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询规格。参数 名称和值
 * @return {*}
 */
export async function getParamDict(payload) {
  try {
    const data = await axiosInstance.post(apis.getParamDict, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 回车新增品牌getProductDetail
 * @return {*}
 */
export async function addBrand(payload) {
  try {
    const data = await axiosInstance.post(apis.addBrand, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 获取商品详情
 * @return {*}
 */
export async function getProductDetail(payload) {
  try {
    const data = await axiosInstance.get(apis.getProductDetail, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 获取店铺运费模板
 * @return {*}
 */
export async function getFreightMethod(payload) {
  try {
    const data = await axiosInstance.get(apis.getFreightMethod, { params: payload })
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询用户信息认证状态
 * @return {*}
 */
export async function queryStatusInfo(payload) {
  try {
    const data = await axiosInstance.get(apis.queryStatusInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 修改企业信息
 * @return {*}
 */
export async function updateInformation(payload) {
  try {
    const data = await axiosInstance.post(apis.updateInformation, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}
/**
 * @description: 查询企业信息
 * @return {*}
 */
export async function queryCompanyInformation(payload) {
  try {
    const data = await axiosInstance.get(apis.queryCompanyInformation, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}
/**
 * @description: 商家端查询个人企业信息
 * @return {*}
 */
export async function queryCompanyInfo(payload) {
  try {
    const data = await axiosInstance.get(apis.queryCompanyInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 商家修改企业信息
 * @return {*}
 */
export async function submitCompanyRecordInfo(payload) {
  try {
    const data = await axiosInstance.post(apis.submitCompanyRecordInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 商家端查询个人店铺信息（商家端）
 * @return {*}
 */
export async function queryStoreInfo(payload) {
  try {
    const data = await axiosInstance.get(apis.queryStoreInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: （店铺认证）发送手机验证码
 * @return {*}
 */
export async function sendPhoneCode(payload) {
  try {
    const data = await axiosInstance.get(apis.sendPhoneCode, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 校验手机验证码
 * @return {*}
 */
export async function validatePhoneCode(payload) {
  try {
    const data = await axiosInstance.get(apis.validatePhoneCode, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询物流信息
 * @return {*}
 */
export async function findLogisticsManageInfo(payload) {
  try {
    const data = await axiosInstance.get(apis.findLogisticsManageInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 编辑物流信息
 * @return {*}
 */
export async function saveAndUpdateLogisticsManageInfo(payload) {
  try {
    const data = await axiosInstance.post(apis.saveAndUpdateLogisticsManageInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 新建运费模版
 * @return {*}
 */
export async function saveAndUpdateFreightTemplate(payload) {
  try {
    const data = await axiosInstance.post(apis.saveAndUpdateFreightTemplate, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询运费模版列表
 * @return {*}
 */
export async function paginationList(payload) {
  try {
    const data = await axiosInstance.post(apis.paginationList, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询运费模版详情
 * @return {*}
 */
export async function editFreightModule(payload) {
  try {
    const data = await axiosInstance.get(apis.editFreightModule, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 删除运费模版
 * @return {*}
 */
export async function delFreightModule(payload) {
  try {
    const data = await axiosInstance.get(apis.delFreightModule, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查询是否已配置默认运费模版
 * @return {*}
 */
export async function queryFreightModule(payload) {
  try {
    const data = await axiosInstance.get(apis.queryFreightModule, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 设置为默认模版
 * @return {*}
 */
export async function setUpFreightTemplate(payload) {
  try {
    const data = await axiosInstance.get(apis.setUpFreightTemplate, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 根据状态分页查询资质列表
 * @return {*}
 */
export async function queryPageInfoByStoreId(payload) {
  try {
    const data = await axiosInstance.post(apis.queryPageInfoByStoreId, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 一级商品类目(展示启用的数据)
 * @return {*}
 */
export async function nodeFirstTree(payload) {
  try {
    const data = await axiosInstance.get(apis.nodeFirstTree, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}


/**
 * @description: 提交资质
 * @return {*}
 */
export async function submitAuditInfo(payload) {
  try {
    const data = await axiosInstance.post(apis.submitAuditInfo, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查看店铺资质-品牌详情
 * @return {*}
 */
export async function queryOne(payload) {
  try {
    const data = await axiosInstance.post(apis.queryOne, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 查看店铺资质-品牌审核详情
 * @return {*}
 */
export async function queryAuditLog(payload) {
  try {
    const data = await axiosInstance.get(apis.queryAuditLog, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 获取需要授权商品品牌列表
 * @return {*}
 */
export async function authorizationProductBrands(payload) {
  try {
    const data = await axiosInstance.get(apis.authorizationProductBrands, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
 * @description: 判断目标类目需要授权
 * @return {*}
 */
export async function verifyAuthorize(payload) {
  try {
    const data = await axiosInstance.post(apis.verifyAuthorize, payload)
    return data
  } catch (e) {
    console.log(e)
  }
}

/**
* @description:  上传文件
* @return {*}
*/
export async function uploadFile(payload){
  try{
    const data = await axiosInstance.post(apis.uploadFile, payload, {  CustomContentType: 'multipart/form-data' })
    return data
  }catch(e){
    console.log(e)
  }
}
