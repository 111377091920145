import Vue from 'vue';
function checkArray(key) { // 权限检查--后期在这里更新逻辑
    let arr = ['1', '2', '3', '4', '5']
    return arr.includes(key)
}

Vue.directive('permission', {
    bind(el, binding, vnode) {
        // 在绑定时，判断用户是否有权限访问该元素
        if (!checkArray(binding.value)) {
            el.style.display = 'none'; // 使用 CSS 样式来隐藏元素
        }
    },
    update(el, binding, vnode) {
        // 在更新时，也需要重新判断权限，并进行相应的操作
        if (!checkArray(binding.value)) {
            el.style.display = 'none'; // 使用 CSS 样式来隐藏元素
        }
    }
});