import { uploadFile } from '@/service/request'

export async function uploadFileFn(files) {
    const formData = new FormData()
    for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i])
    }
    const res = await uploadFile(formData)
    if(res?.success){
        return res
    }
    return { success: false, data: null}
}