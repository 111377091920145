 // 获取系统默认语言
 const SystemDefaultLanguage = () => {
    let language = navigator.language || navigator.userLanguage;
    let systemLanguage = ''
    if (language.startsWith('en')) {
        // '英语'
        systemLanguage = 'en'
    } else if (language.startsWith('zh-CN')) {
        // '简体中文'
        systemLanguage = 'zhCN'
    } else if (language.startsWith('zh-TW') || language.startsWith('zh-HK')) {
        // '繁体中文'
        systemLanguage = 'zhTW'
    } else {
        // '简体中文'
        systemLanguage = 'zhCN'
    }
    return systemLanguage;
  }

  module.exports = {
    SystemDefaultLanguage
};