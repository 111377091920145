<!--
 * @Description: 
 * @Author: luis
 * @Date: 2023-11-21
 * @LastEditors: luis
 * @FilePath: \merchantsys-vue\src\components\SvgIcon\index.vue
-->
<template>
  <svg fill="none" :style="sizeStyle">
    <g>
      <path class="svg-path" :d="SvgPath[path]" :fill="color" fill-rule="evenodd" fill-opacity="1" />
    </g>
  </svg>
</template>
<script>
import { SvgPath } from '@/components/SvgIcon/staticData.js'
export default {
  props: {
    color: {
      type: String,
      default: '#747374'
    },
    size: {
      type: String,
      default: '20px'
    },
    path: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    }
  },
  data(){
    return {
      svgColor: ''
    }
  },
  computed: {
    SvgPath: () => {
      return SvgPath
    },
    sizeStyle(){
      let width = this.width || this.size
      let height = this.height || this.size
      return {width, height}
    }
  },
}
</script>
<style lang="scss" scoped>
.hover-class path{
  fill: #3DB2FF!important;
}
</style>
