const clearNoNum = (value) => {
  value = value.replace(/[^\d.]/g, '') // 清除"数字"和"."以外的字符
  value = value.replace(/^\./g, '') // 验证第一个字符是数字而不是字符
  value = value.replace(/\.{2,}/g, '.') // 只保留第一个.清除多余的
  value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
  value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
  if (value.indexOf('.') < 0 && value !== '') {
    value = parseFloat(value)
  }
  return value
}

const cardFormat = (value) => {
  // 保留输入中的数字
  let digits = value.replace(/\D/g, '');
  // 限制输入为最多9位数字
  return digits
}

const stringUtils = {
  clearNoNum: clearNoNum, // 只允许输入金额
  cardFormat: cardFormat // 纯数字
}

export default stringUtils
