
import E from '@/sourceCode/wangeditor/dist/wangEditor'
const { BtnMenu } = E
import { HTMLFormat } from './upload/util'
export default (safe) => {
  return class Plugin extends BtnMenu {
    constructor(editor) {
      
      const $elem = E.$()
      super($elem, editor)
    }
    clickHandler () {
      safe.text = HTMLFormat(safe.text)
      safe.show = true;
    }
    tryChangeActive () {
      this.active()
    }
  }
}