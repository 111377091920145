
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locale from 'element-ui/lib/locale';

// Element UI语言包
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import twLocale from 'element-ui/lib/locale/lang/zh-TW';

// 翻译文件
import en from './en.json'; //  英文翻译文件
import zhCN from './zh-CN.json'; // 简体中文翻译文件
import zhTW from './zh-TW.json'; // 繁体中文翻译文件
const Languages = require("@/utils/Languages.js")
let systemLanguage = Languages.SystemDefaultLanguage()


// 首页中、英、繁体切换功能
Vue.use(VueI18n);

const messages = {
    en: { ...en, ...enLocale },
    zhCN: { ...zhCN, ...zhLocale },
    zhTW: { ...zhTW, ...twLocale }
  };
 
  const i18n = new VueI18n({
    locale: systemLanguage, // 默认语言
    // locale: 'en',
    messages,
    silentTranslationWarn: true, // 设置为 true 以禁止翻译键缺失的警告
  });

 // 将Element UI组件的语言设置为vue-i18n的当前语言
  locale.i18n((key, value) => i18n.t(key, value));
  
  export default i18n


 
  // 登录注册页面系统默认语言

  
  export  const LoginRegisterI18n = new VueI18n({
    locale: systemLanguage, // 默认语言
    messages,
    silentTranslationWarn: true, // 设置为 true 以禁止翻译键缺失的警告
  });