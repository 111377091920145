<template>
  <div class="avue-ueditor">
    <div :id="id" v-loading="ueditorUploading" element-loading-text="上传中..."/>
    <div v-if="show" class="wangEditor_html-content">
      <div class="wangEditor_html-header">
        <span class="wangEditor_html-close" @click="closeDialog">x</span>
      </div>
      <textarea v-model="text" />
    </div>
  </div>
</template>
<script>
import { getClient } from './upload/ali'
import { getToken } from './upload/qiniu'
import { getAsVal, HTMLFormat, validatenull, uuid } from './upload/util'
import { detailImg, fileToBase64 } from './upload/canvas'
import $ImageCropper from './image-cropper/';
import Plugin from './plugin.js'

import E from '@/sourceCode/wangeditor/dist/wangEditor'
export default {
  name: 'AvueUeditor',
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
    headers: {
      type: Object,
      default: () => {
        return {}
      },
    },
    customConfig: {
      type: Object,
      default: () => {
        return {}
      },
    },
    propsHttp: {
      type: Object,
      default: () => {
        return {}
      },
    },
    canvasOption: {
      type: Object,
      default: () => {
        return {};
      }
    },
    cropperOption: {
      type: Object,
      default: () => {
        return {};
      }
    },
    options: {
      type: Object,
      default: () => {
        return {}
      },
    },
    ali: {
      type: Object,
      default: () => {
        return {}
      },
    },
    qiniu: {
      type: Object,
      default: () => {
        return {}
      },
    },
    oss: String,
    action: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ueditorUploading: false, // 富文本上传loding
      id: 'avue-ueditor-' + uuid(),
      text: '',
      show: false,
      editor: '',
    }
  },
  computed: {
    fileName() {
      return this.propsHttp.fileName || 'file'
    },
    homeUrl() {
      return this.propsHttp.home || ''
    },
    resKey() {
      return this.propsHttp.res
    },
    domId() {
      return '#' + this.id
    },
    imgFlag() {
      return this.img.url == ''
    },
    isImg() {
      return this.img.obj.src
    },
    urlKey() {
      return this.propsHttp.url || 'url'
    },
    isHtml() {
      return validatenull(this.customConfig.showHtml) ? true : this.customConfig.showHtml
    },
    isQiniuOSS() {
      return this.oss === 'qiniu'
    },
    isAliOSS() {
      return this.oss === 'ali'
    }
  },
  watch: {
    disabled(val) {
      if (val) {
        this.editor.disable()
      } else {
        this.editor.enable()
      }
    },
    value(val) {
      if (val !== this.editor.txt.html()) {
        this.text = val;
        this.editor.txt.html(val)
      }
      try {
        this.editor.selection.moveCursor(this.phoneEditor.$textElem.elems[0], false);
      } catch (err) {

      }
    }
  },
  mounted() {
    this.initEdit()
  },
  methods: {
    closeDialog() {
      this.text = HTMLFormat(this.text)
      this.handleChange()
      this.show = false
    },
    handleChange() {
      this.$emit('input', this.text)
      this.$emit('change', this.text)
    },
    initEdit() {
      this.editor = new E(this.domId)
      if (this.isHtml) {
        E.registerMenu('Plugin', Plugin(this))
      }
      this.editor.config.zIndex = 100
      this.editor.config.menuTooltipPosition = 'down'
      this.editor.config.placeholder = this.placeholder
      this.editor.config.showFullScreen = true
      this.editor.config.pasteFilterStyle = false
      this.editor.config.onfocus = () => {
        this.$emit('focus', this.text)
      }
      this.editor.config.onblur = (html) => {
        this.$emit('blur', html)
      }
      this.editor.config.onchange = (html) => {
        this.text = html
        this.handleChange()
      }
      this.editor.config.emotions = [
        {
          title: 'emoji',  // tab 的标题
          type: 'emoji', // 'emoji' / 'image'
          // emoji 表情，content 是一个数组即可
          content: '😀 😃 😄 😁 😆 😅 😂 😊 😇 🙂 🙃 😉 😓 😪 😴 🙄 🤔 😬 🤐'.split(/\s/),
        }
      ]
      this.initUploadImg()
      this.editor.config = Object.assign(
        this.editor.config,
        this.customConfig
      )
      this.editor.config.excludeMenus = [ // 屏蔽部分工具栏
        'link', // 链接
        'todo', // 待办事项
        'quote', // 引用
        'video', // 视频
        'table', // 表格
        'code', // 代码
      ]
      this.editor.config.showFullScreen = false // 不显示全屏
      this.editor.create()
      this.editor.txt.html(this.value)
      if (this.disabled === true) this.editor.disable()
    },
    async initUploadImg() {
      const callback = async (file, insert) => {
        this.ueditorUploading = true
        for (let i = 0; i < file.length; i++) {
          let res = await this.uploadFile(file[i])
          insert(res)
        }
        this.ueditorUploading = false
      }
      this.editor.config.customUploadVideo = callback
      this.editor.config.customUploadImg = callback
    },
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        const done = () => {
          const headers = Object.assign(this.headers, {
            'Content-Type': 'multipart/form-data',
          })
          let oss_config = {}
          let client
          let param = new FormData()
          let url = this.action
          param.append(this.fileName, file)
          Object.keys(this.data).forEach(ele => {
            param.append(ele, this.data[ele])
          })
          if (this.isQiniuOSS) {
            oss_config = this.qiniu
            const token = getToken(oss_config.AK, oss_config.SK, {
              scope: oss_config.scope,
              deadline:
                new Date().getTime() + oss_config.deadline * 3600,
            })
            param.append('token', token)
            url = 'http://up.qiniu.com/'
          } else if (this.isAliOSS) {
            oss_config = this.ali
            client = getClient({
              region: oss_config.region,
              endpoint: oss_config.endpoint,
              accessKeyId: oss_config.accessKeyId,
              accessKeySecret: oss_config.accessKeySecret,
              bucket: oss_config.bucket,
            })
          }
          (() => {
            if (this.isAliOSS) {
              return client.put(file.name, file)
            } else {
              return this.$axios.post(url, param, { headers })
            }
          })().then((res) => {
            let list = {}
            var result = ''
            if (this.isAliOSS) {
              list = res
              result = list.url
            } else if (this.isQiniuOSS) {
              list = res.data
              list.key = oss_config.url + list.key
              result = list.key
            } else {
              if (typeof (this.resKey) === 'function') {
                result = this.resKey(res.data);
              } else {
                list = getAsVal(res.data, this.resKey);
                result = list[this.urlKey]
              }
            }
            var html = this.homeUrl + result
            resolve(html)
          })
            .catch((err) => {
              reject(err)
            })
        }
        //处理水印图片
        const canvasDone = () => {
          if (!validatenull(this.canvasOption)) {
            detailImg(file, this.canvasOption, res => {
              file = res;
              done();
            });
          } else {
            done()
          }
        }
        //处理图片剪裁
        if (!validatenull(this.cropperOption)) {
          fileToBase64(file, (res) => {
            let option = Object.assign(this.cropperOption, {
              img: res,
              type: 'file',
              callback: res => {
                file = res;
                canvasDone()
              }
            })
            $ImageCropper(option)
          })
        } else {
          canvasDone()
        }
      })
    },
    getUEContent() {
      return this.editor.txt.html()
    }
  }
}
</script>
<style>
.wangEditor_html {
  color: #999;
  font-size: 14px;
  font-weight: bold;
}

.wangEditor_html-content {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2048;
  padding: 0 20px;
  background-color: #fff;
  box-sizing: border-box;
}

.wangEditor_html-content textarea {
  width: 100%;
  height: 90%;
  border-color: #ccc;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
}

.wangEditor_html-header {
  padding: 5px 0;
  text-align: right;
}

.wangEditor_html-close {
  color: #333;
  font-weight: bold;
  font-size: 22px;
}
</style>
