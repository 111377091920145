<template>
  <div class="submitQualification">
    <div class="pageHeader">
      <div class="toBack" v-hover @click="$router.back()">
        <svg-icon class="shrink" path="toBack" color="#747374" size="16px" />
      </div>
      <div class="pageTitle">{{$t('提交授权')}}</div>
    </div>
    <el-card v-if="detail">
      <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-position="top" class="demo-dynamic">
        <el-form-item :label="$t('商品品牌')" prop="relateId" :rules="{ required: true, message: $t('请选择商品品牌'), trigger: 'change' }">
          <el-select v-model="dynamicValidateForm.relateId" :placeholder="$t('请选择商品品牌')" @change="onChange">
            <el-option
              v-for="categoryItem in brandData"
              :key="categoryItem.value"
              :label="categoryItem.label"
              :value="categoryItem.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('品牌授权')"
          prop="picUrl"
          :rules="{
            required: true,
            message: $t('请上传品牌授权'),
            trigger: 'blur'
          }"
        >
          <MyUpload
            v-model="dynamicValidateForm.picUrl"
            :limit="1"
            tipPlacement="tipPlacement"
            :tip="$t('图片大小不能超过4M，图片格式为jpg、jpeg、png。')"
          />
        </el-form-item>
        <el-form-item>
          <div style="display: flex; justify-content: flex-end">
            <el-button @click="resetForm('dynamicValidateForm')">{{$t('取消')}}</el-button>
            <el-button type="primary" @click="submitForm('dynamicValidateForm')">{{$t('提交')}}</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon'
import MyUpload from '@/components/myUpload'
import { submitAuditInfo, authorizationProductBrands, queryOne } from '@/service/request'
export default {
  components: {
    SvgIcon,
    MyUpload
  },
  data() {
    return {
      dynamicValidateForm: {
        picUrl: '',
        relateId: ''
      },
      brandData: [],
      detail: null
    }
  },
  async mounted() {
    this.initDictData()
  },
  methods: {
    async initDictData() {
      // 初始化筛选条件字典
      const { data: newData } = await authorizationProductBrands()
      this.brandData = newData.map((item) => {
        let obj = item
        obj.label = item.productBrandName
        obj.value = item.id
        return obj
      })
      this.queryOne()
    },
    queryOne() {
      let params = {
        id: Number(this.$route.query?.id),
        type: 2
      }
      queryOne(params).then((res) => {
        this.dynamicValidateForm = {
          picUrl: res.data.picUrl,
          relateId: res.data.relateId
        }
        this.detail = res.data
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            id: Number(this.$route.query.id),
            relateId: this.detail.relateId,
            ...this.dynamicValidateForm,
            type: 2
          }
    
          submitAuditInfo(params).then((res) => {
            if (res.code == 200) {
              this.$message.success(this.$t('提交成功'))
              this.$router.back()
            }
          })
        } else {
          
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.$router.back()
    },
    onChange(val) {}
  }
}
</script>

<style lang="scss" scoped>
.submitQualification {
  .pageHeader {
    padding: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .pageTitle {
      font-size: 18px;
      font-weight: 600;
      color: #1d1919;
    }

    .toBack {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      box-sizing: border-box;
      border: 1px solid #a0a0a1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
      cursor: pointer;

      .shrink {
        transform: scale(0.8);
      }
    }

    .toBack:hover {
      border: 1px solid #3db2ff;
    }
  }
}
</style>
