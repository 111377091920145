/*
 * @Description:
 * @Author: luis
 * @Date: 2023-11-18
 * @LastEditors: luis
 * @FilePath: \merchantsys-vue\src\store\modules\user.js
 */
const state = {
  userName: '',
  userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null
}

const getters = {
  // 获取 userName 的值
  getUserName: (state) => {
    return state.userName
  }
}

const mutations = {
  MT_USERNAME: (state, payload) => {
    state.userName = payload
  },
  SET_USERINFO(state, payload) {
    localStorage.setItem('userInfo', JSON.stringify(payload))
    state.userInfo = payload
  },
  DEL_USERINFO(state){
    localStorage.removeItem('userInfo')
    state.userInfo = null
  }
}

const actions = {
  AT_USERNAME({ commit }, payload) {
    commit('MT_USERNAME', payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
