<template>
  <div class="upLoadPage">
    <div class="uploadBox" :class="tipPlacement">
      <input type="file" ref="fileInput" :disabled="isDisabled" :multiple="multiple" :accept="accept + '/*'"
        @change="handleChange" style="display: none" />
      <div class="contentBox">
        <draggable class="moveBox" v-model="fileList" @sort="sortChange" draggable=".imgItem">
          <div class="imgItem" v-for="(item, index) in fileList" :key="item.url">
            <img v-if="getFileType(item.url) === 'img'" :src="item.thumbnailURL" alt="" />
            <video v-else :src="item.thumbnailURL"></video>
            <div class="viewBox">
              <span class="iconItem" @click="viewItem(index)"><i class="el-icon-zoom-in"></i></span>
              <span v-if="isShowDeleteBtn" class="iconItem" @click="deleteItem(item)"><i
                  class="el-icon-delete"></i></span>
            </div>
          </div>
          <div class="upIcon" v-loading="loading" v-if="fileList.length < limit" draggable
            @click="!loading && $refs.fileInput.click()">
            <slot name="uploadText">
              <i class="el-icon-plus"></i>
            </slot>
          </div>
        </draggable>
      </div>
      <div class="tipBox">
        <slot name="tip">
          {{ tip }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { uploadFileFn } from '@/utils/uploadJs'
export default {
  components: { draggable },
  props: {
    value: {},
    maxSize: {
      // 最大尺寸 2M
      type: Number,
      default: 2
    },
    limit: {
      // 数量限制
      type: Number,
      default: 2
    },
    accept: {
      // 文件类型
      type: String,
      default: 'image'
    },
    tip: {
      // 提示
      type: String,
      default: '建议尺寸750*750或750*1000，大小在2M以内，可拖拽调整图片顺序，最多上传10张。'
    },
    exceedMsg: {
      // 数量超限提示
      type: String,
      default: '数量超出上限'
    },
    multiple: {
      // 多选同时上传
      type: Boolean,
      default: false
    },
    tipPlacement: {
      // 提示语位置
      type: String,
      default: ''
    },
    isDisabled: {
      // 是否禁用
      type: Boolean,
      default: false
    },
    isShowDeleteBtn: {
      // 是否展示删除按钮
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      uid: ''
    }
  },
  watch: {},
  async created() {
    this.uid = this.$store.state.user.userInfo.id || 'noUid'
    await this.$nextTick()
    this.initFilelist()
    this.uid === 'noUid' && console.error(this.$t('myUpLoad组件参数：附件Uid缺失'))
  },
  data() {
    return {
      fileList: [],
      loading: false,
      inputValue: ''
    }
  },
  methods: {
    sortChange() { // 排序变化后更新值
      this.changeValue()
    },
    initFilelist(data = false) {
      let imgData = data ? data : this.value
      let isArr = Array.isArray(imgData)
      if (isArr) {// v-model  为 Array
        imgData.forEach((item) => {
          this.fileList.push({ url: item, thumbnailURL: item })
        })
      } else {// v-model  为 Strins ---- 限制limit为1
        if (!imgData) return
        this.fileList = [{ url: imgData, thumbnailURL: imgData  }]
      }
    },
    getFileType(fileUrl) {
      var extension = fileUrl.split('.').pop()
      if (extension && extension.length > 0) {
        var fileType = extension.toLowerCase()

        if (fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif' || fileType === 'webp') {
          return 'img'
        } else if (fileType === 'mp4' || fileType === 'mov' || fileType === 'avi' || fileType === 'wmv') {
          return 'video'
        }
      }
    },
    changeValue() { // 更新
      let isArr = Array.isArray(this.value) //
      let resValue
      if (isArr) {
        resValue = this.fileList.map(({ url }) => {
          return url
        })
      } else {
        resValue = this.fileList[0]?.url || ''
      }
      this.$emit('input', resValue) // 触发父组件的 v-model 更新
    },
    checkFile(files) {
      const maxNum = this.limit
      for (let i = 0; i < files.length; i++) {
        if (this.fileList.length >= maxNum) {// 超出数量限制
          this.exceed()
          return false
        }
        let fileType = files[i].type.startsWith(this.accept)
        if (!fileType) {
          this.$showMessage({
            icon: 'failed',
            msg: this.$t(`请选择 ${this.accept} 类型文件`)
          })
          return false
        }
        let fileSize = files[i].size / 1024 / 1024
        if (fileSize > this.maxSize) {
          this.$showMessage({
            icon: 'failed',
            msg: this.$t(`文件超出${this.maxSize}M，请调整文件大小后重新上传`)
          })
          return false
        }
        return true
      }
    },
    async handleChange() { // 选择文件后 判断是否上传
      this.loading = true
      const files = this.$refs.fileInput.files
      if (this.checkFile(files)) {
        const { success, data } = await uploadFileFn(files)
        if (success) {
          data.forEach((item, index) => {
            if (item.status === 1) {
              this.$showMessage({ icon: 'succeed', msg: files[index].name + '上传成功' })
              this.fileList.push({ url: item.url, thumbnailURL: item.url })
              console.log(this.fileList, '====')
            } else {
              this.$showMessage({ msg: files[index].name + '上传失败', icon: 'warn' })
            }
          })
          this.changeValue() // 上传后 回传值
        } else {
          this.$showMessage({  icon: 'failed',  msg: this.$t('上传失败请，请稍后重试!') })
        }
      }else{ // 不清理value,选择同样的失败的文件名，会不触发change
        this.$refs.fileInput.value = ''
      }
      this.loading = false
    },
    viewItem(index) {
      // 放大预览
      this.$ImagePreview(this.fileList, index)
    },
    deleteItem(item) {
      // 删除
      this.fileList = this.fileList.filter(({ url }) => url !== item.url)
      this.$refs.fileInput.value = ''
      let isArr = Array.isArray(this.value) //
      let resValue
      if (isArr) {
        resValue = this.fileList.map(({ url }) => {
          return url
        })
      } else {
        resValue = ''
      }
      this.$emit('input', resValue) // 触发父组件的 v-model 更新
    },
    exceed() {
      this.$showMessage({
        icon: 'failed',
        msg: this.exceedMsg
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.tipBox {
  letter-spacing: 0.005em;
  font-size: 12px;
  color: #a0a0a1;
}

.uploadBox {
  width: 100%;
  height: 100%;

  .contentBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    ::v-deep .el-loading-mask {
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-loading-spinner {
        position: relative;
        top: 0;
        left: 0;
        margin-top: 0;
      }
    }

    .upIcon {
      border: 1px dashed #c0ccda;
      overflow: hidden;
      border-radius: 6px;
      box-sizing: border-box;
      width: 148px;
      height: 148px;
      cursor: pointer;
      line-height: 146px;
      vertical-align: top;
      font-size: 28px;
      color: #8c939d;
      text-align: center;
      margin: 0 8px 8px 0;
    }

    .upIcon:hover {
      border-color: #409eff;
      color: #409eff;
    }

    .moveBox {
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .imgItem {
        width: 148px;
        height: 148px;
        border-radius: 6px;
        overflow: hidden;
        margin: 0 8px 8px 0;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #e7e7e7;
        object-fit: cover;

        >img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        >video{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .viewBox {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
          opacity: 0;
          z-index: 99;
          display: flex;
          align-items: center;
          justify-content: center;

          .iconItem {
            font-size: 20px;
            margin: 10px;
            cursor: pointer;
          }

          .iconItem:hover {
            color: #409eff;
          }
        }

        .viewBox:hover {
          opacity: 1;
        }
      }
    }
  }
}

.tipPlacement {
  display: flex;
  align-items: flex-end;
}

.top {
  display: flex;
  flex-direction: column;

  /* 垂直排列子元素 */
  .tipBox {
    order: 1;
  }

  .contentBox {
    order: 2;
  }
}
</style>
